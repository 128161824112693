module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"/home/circleci/team-practice-alpha/node_modules/gatsby-remark-images","id":"6d72660d-d259-5ce7-8f5d-60a6bbc00710","name":"gatsby-remark-images","version":"6.2.0","modulePath":"/home/circleci/team-practice-alpha/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"maxWidth":590,"linkImagesToOriginal":false},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"extensions":[".mdx"],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/circleci/team-practice-alpha","commonmark":false},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590,"linkImagesToOriginal":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
