import {Row, RowType, TableData} from "../../components/Table/obj/TableData";
import {Bridge} from "../../objects/Bridge";
import {ClassMember} from "../../objects/SoftwareAPI";

const N = "**N**", Y = "Y",
    BOOL = "bool", STRING = "string", DOUBLE = "double",
    DENTRIX = "Dentrix"

const INIT = "Init",
    SHOW_CONFIG_FORM = "ShowConfigForm",
    GET_METADATA = "GetMetaData",
    GET_CUSTOMERS = "GetCustomers",
    GET_PROVIDERS = "GetProviders",
    GET_SHORT_NOTICE_LIST = "GetShortNoticeList",
    GET_APPOINTMENTS = "GetAppointments",
    GET_PROCEDURES = "GetProcedures",
    GET_RECARES = "GetRecares",
    SET_APPOINTMENTS_AS_CONFIRMED = "SetAppointmentsAsConfirmed",
    SET_COMM_MESAGE_HISTORY = "SetCommMessageHistory"



const api_Init = new ClassMember(INIT, "Initialize bridge", BOOL, "string appDataDir" )
const api_ShowConfigForm = new ClassMember(SHOW_CONFIG_FORM, "Shows the configuration window for this bridge")

const api_GetMetaData = new ClassMember(GET_METADATA, "Retrieve Meta Data, Recare Types", STRING)
const api_GetCustomers = new ClassMember(GET_CUSTOMERS, "Get all customers in xml format", STRING)
const api_GetProviders = new ClassMember(GET_PROVIDERS, "Get all providers in xml format", STRING)
const api_GetShortNoticeList = new ClassMember(GET_SHORT_NOTICE_LIST, "Get list of customers on the short notice list", STRING)

const param_SpecifiedTime = "DateTime startDate, DateTime endDate"
const api_GetAppointments = new ClassMember(GET_APPOINTMENTS, "Get all appointments within specified time in xml format", STRING, param_SpecifiedTime)
const api_GetProcedures = new ClassMember(GET_PROCEDURES, "Get all procedures within specified time in xml format", STRING, param_SpecifiedTime)
const api_GetRecares = new ClassMember(GET_RECARES, "Get all **recalls** within specified time in xml format", STRING, param_SpecifiedTime)

const api_SetAppointmentsAsConfirmed = new ClassMember(SET_APPOINTMENTS_AS_CONFIRMED, "", "AppointmentConfirmation[]", "AppointmentConfirmation[] confirmations")
const api_SetCommMessageHistory = new ClassMember(SET_COMM_MESAGE_HISTORY, "", "CommMessage[]", "CommMessage[] messages")

const init = new ClassMember(INIT, "Initialize bridge", BOOL, "string appDataDir" )
const showConfigForm = new ClassMember(SHOW_CONFIG_FORM, "Shows the configuration window for this bridge")

const getMetaData = new ClassMember(GET_METADATA, "Retrieve Meta Data, Recare Types", STRING)
const getCustomers = new ClassMember(GET_CUSTOMERS, "Get all customers in xml format", STRING)
const getProviders = new ClassMember(GET_PROVIDERS, "Get all providers in xml format", STRING)
const getShortNoticeList = new ClassMember(GET_SHORT_NOTICE_LIST, "Get list of customers on the short notice list", STRING)

const getAppointments = new ClassMember(GET_APPOINTMENTS, "Get all appointments within specified time in xml format", STRING, param_SpecifiedTime)
const getProcedures = new ClassMember(GET_PROCEDURES, "Get all procedures within specified time in xml format", STRING, param_SpecifiedTime)
const getRecares = new ClassMember(GET_RECARES, "Get all **recalls** within specified time in xml format", STRING, param_SpecifiedTime)

const setAppointmentsAsConfirmed = new ClassMember(SET_APPOINTMENTS_AS_CONFIRMED, "", "AppointmentConfirmation[]", "AppointmentConfirmation[] confirmations")
const setCommMessageHistory = new ClassMember(SET_COMM_MESAGE_HISTORY, "", "CommMessage[]", "CommMessage[] messages")


// Members
const memberMap = new Map([
  [api_Init.id, api_Init]
])

const dummyMap =
    new Map([
  [init.id, ],
  [showConfigForm.id, ],
  [getMetaData.id, ],
  [getCustomers.id, ],
  [getProviders.id, ],
  [getShortNoticeList.id, ],
  [getAppointments.id, ],
  [getProcedures.id, ],
  [getRecares.id, ],
  [setAppointmentsAsConfirmed.id, ],
  [setCommMessageHistory.id, ],
])

// Bridges
const handler = new Bridge("Handler","","","","", "",
    new Map([
      [init.id, "`Load()`"],
      [showConfigForm.id, Y],
      [getMetaData.id, N],
      [getCustomers.id, Y],
      [getProviders.id, Y],
      [getShortNoticeList.id, Y],
      [getAppointments.id, Y],
      [getProcedures.id, Y],
      [getRecares.id, Y],
      [setAppointmentsAsConfirmed.id, Y],
      [setCommMessageHistory.id, Y],
    ]))
const dtx_1_0 = new Bridge('1.0', DENTRIX,  '9 - G4', '1.0', '1.01')
const dtx_1_1 = new Bridge('1.1', DENTRIX,  'G6+', '1.0', '1.02', '1.03',
    new Map([
      [init.id, Y],
      [showConfigForm.id, Y],
      [getMetaData.id, N],
      [getCustomers.id, Y],
      [getProviders.id, N],
      [getShortNoticeList.id, Y],
      [getAppointments.id, Y],
      [getProcedures.id, N],
      [getRecares.id, Y],
      [setAppointmentsAsConfirmed.id, Y],
      [setCommMessageHistory.id, N],
    ]))
const dtx_1_2 = new Bridge('1.2', DENTRIX,  'G6.2+', '1.0', '1.02', '1.04',
    new Map([
      [init.id, Y],
      [showConfigForm.id, Y],
      [getMetaData.id, N],
      [getCustomers.id, Y],
      [getProviders.id, N],
      [getShortNoticeList.id, Y],
      [getAppointments.id, Y],
      [getProcedures.id, N],
      [getRecares.id, Y],
      [setAppointmentsAsConfirmed.id, Y],
      [setCommMessageHistory.id, N],
    ]))
const dtx_1_3 = new Bridge('1.3', DENTRIX,  'G6.2+', '1.0', '1.03', '1.05',
    new Map([
      [init.id, Y],
      [showConfigForm.id, Y],
      [getMetaData.id, N],
      [getCustomers.id, Y],
      [getProviders.id, N],
      [getShortNoticeList.id, Y],
      [getAppointments.id, Y],
      [getProcedures.id, N],
      [getRecares.id, Y],
      [setAppointmentsAsConfirmed.id, Y],
      [setCommMessageHistory.id, N],
    ]))
const abel_2_0 = new Bridge('2.0','Abel (SQL)',  '9 - 14', '1.0', '1.01', '1.02',
    new Map([
      [init.id, Y],
      [showConfigForm.id, Y],
      [getMetaData.id, N],
      [getCustomers.id, Y],
      [getProviders.id, N],
      [getShortNoticeList.id, Y],
      [getAppointments.id, Y],
      [getProcedures.id, N],
      [getRecares.id, Y],
      [setAppointmentsAsConfirmed.id, Y],
      [setCommMessageHistory.id, N],
    ]))
const clear_3_0 = new Bridge('3.0','Cleardent',  'All', '1.0', '1.02', '1.03',
    new Map([
      [init.id, Y],
      [showConfigForm.id, Y],
      [getMetaData.id, N],
      [getCustomers.id, Y],
      [getProviders.id, N],
      [getShortNoticeList.id, Y],
      [getAppointments.id, Y],
      [getProcedures.id, N],
      [getRecares.id, Y],
      [setAppointmentsAsConfirmed.id, Y],
      [setCommMessageHistory.id, N],
    ]))
const eagle_4_0 = new Bridge('4.0','Eaglesoft',  '15+', '1.0', '1.0', '1.01',
    new Map([
      [init.id, Y],
      [showConfigForm.id, Y],
      [getMetaData.id, N],
      [getCustomers.id, Y],
      [getProviders.id, N],
      [getShortNoticeList.id, Y],
      [getAppointments.id, Y],
      [getProcedures.id, N],
      [getRecares.id, Y],
      [setAppointmentsAsConfirmed.id, Y],
      [setCommMessageHistory.id, N],
    ]))
const power_7_0 = new Bridge('7.0','Power Practice',  '4.3 - 4.9', '**4.0**', '4.25', '4.26',
    new Map([
      [init.id, Y],
      [showConfigForm.id, Y],
      [getMetaData.id, N],
      [getCustomers.id, Y],
      [getProviders.id, N],
      [getShortNoticeList.id, Y],
      [getAppointments.id, Y],
      [getProcedures.id, N],
      [getRecares.id, Y],
      [setAppointmentsAsConfirmed.id, Y],
      [setCommMessageHistory.id, N],
    ]))
const maxi_8_0 = new Bridge('8.0','MaxiDent MDB',  '5', '1.0', '1.01', '1.02',
    new Map([
      [init.id, Y],
      [showConfigForm.id, Y],
      [getMetaData.id, N],
      [getCustomers.id, Y],
      [getProviders.id, N],
      [getShortNoticeList.id, Y],
      [getAppointments.id, Y],
      [getProcedures.id, N],
      [getRecares.id, Y],
      [setAppointmentsAsConfirmed.id, Y],
      [setCommMessageHistory.id, N],
    ]))
const trkMdb_9_0    = new Bridge('9.0','Tracker MDB',  '10.x', '1.0', '1.01', "1.02",
    new Map([
      [init.id, Y],
      [showConfigForm.id, Y],
      [getMetaData.id, N],
      [getCustomers.id, Y],
      [getProviders.id, N],
      [getShortNoticeList.id, Y],
      [getAppointments.id, Y],
      [getProcedures.id, N],
      [getRecares.id, Y],
      [setAppointmentsAsConfirmed.id, Y],
      [setCommMessageHistory.id, N],
    ]))
const trkSql_9_1    = new Bridge('9.1','Tracker SQL',  '11+', '1.0', '1.0', "1.01",
    new Map([
      [init.id, Y],
      [showConfigForm.id, Y],
      [getMetaData.id, N],
      [getCustomers.id, Y],
      [getProviders.id, N],
      [getShortNoticeList.id, Y],
      [getAppointments.id, Y],
      [getProcedures.id, N],
      [getRecares.id, Y],
      [setAppointmentsAsConfirmed.id, Y],
      [setCommMessageHistory.id, N],
    ]))
const gold_10_0     = new Bridge('10.0','Gold',  '10+', '**5.0**', '5.02', '5.03',
    new Map([
      [init.id, Y],
      [showConfigForm.id, Y],
      [getMetaData.id, N],
      [getCustomers.id, Y],
      [getProviders.id, N],
      [getShortNoticeList.id, N],
      [getAppointments.id, Y],
      [getProcedures.id, N],
      [getRecares.id, Y],
      [setAppointmentsAsConfirmed.id, Y],
      [setCommMessageHistory.id, N],
    ]))
const dntVis_12_0   = new Bridge('12.0','Dental Vision Enterprise',  '13 - 17', '**4.0**', '4.07', '4.08',
    new Map([
        [init.id, Y],
        [showConfigForm.id, Y],
        [getMetaData.id, N],
        [getCustomers.id, Y],
        [getProviders.id, N],
        [getShortNoticeList.id, Y],
        [getAppointments.id, Y],
        [getProcedures.id, N],
        [getRecares.id, Y],
        [setAppointmentsAsConfirmed.id, Y],
        [setCommMessageHistory.id, N],
    ]), "This Bridge is not available on CloudSync")
const opnDnt_13_0   = new Bridge('13.0','OpenDental',  '12+', '1.0', '1.01')
const liveDDM_13_0  = new Bridge('14.0','LiveDDM',  '5.0.x', '1.0', '1.02', '1.03',
    new Map([
        [init.id, Y],
        [showConfigForm.id, Y],
        [getMetaData.id, N],
        [getCustomers.id, Y],
        [getProviders.id, N],
        [getShortNoticeList.id, Y],
        [getAppointments.id, Y],
        [getProcedures.id, N],
        [getRecares.id, Y],
        [setAppointmentsAsConfirmed.id, Y],
        [setCommMessageHistory.id, Y],
    ]))
const paradigm_16_0 = new Bridge('16.0','Paradigm',  'v6', '4.0', '4.46')

// Bridge Map
// const bridgeMap = new Map([
//   [handler.id, handler],
//
//   [dtx_1_0.id, dtx_1_0],
//   [dtx_1_1.id, dtx_1_1],
//   [dtx_1_2.id, dtx_1_2],
//   [dtx_1_3.id, dtx_1_3],
//
//   [abel_2_0.id, abel_2_0],
//   [clear_3_0.id, clear_3_0],
//   [eagle_4_0.id, eagle_4_0],
//   [power_7_0.id, power_7_0],
//   [maxi_8_0.id, maxi_8_0],
//   [trkMdb_9_0.id, trkMdb_9_0],
//   [trkSql_9_1.id, trkSql_9_1],
//
//   [gold_10_0.id, gold_10_0],
//   [dntVis_12_0.id, dntVis_12_0],
//   [opnDnt_13_0.id, opnDnt_13_0],
//   [liveDDM_13_0.id, liveDDM_13_0],
//   [paradigm_16_0.id, paradigm_16_0],
// ])

// Arrays
const members = [
    api_Init, api_ShowConfigForm,
    api_GetMetaData, api_GetCustomers, api_GetProviders, api_GetShortNoticeList,
    api_GetAppointments, api_GetProcedures, api_GetRecares,
    api_SetAppointmentsAsConfirmed, api_SetCommMessageHistory
]
const bridges = [
  handler,
  dtx_1_0, dtx_1_1, dtx_1_2, dtx_1_3,
  abel_2_0,
  clear_3_0,
  eagle_4_0,
  power_7_0,
  maxi_8_0,
  trkMdb_9_0, trkSql_9_1,
  gold_10_0,
  dntVis_12_0,
  opnDnt_13_0,
  liveDDM_13_0,
  paradigm_16_0
]

// Public functions
export const getMembersTable = () => {

  const membersTable = new TableData([
    new Row([ "Member" ], RowType.HEADER),
  ])

  // Assign bridge IDs to header
  for(const b of bridges) {
    const col = `\`${b.id}\``     // Code formatted
    membersTable.rows[0].cells.push(col)
  }

  // Push rows
  // for (const m of members) {
  for (let i = 0; i < members.length; i++) {
    const m = members[i]

    const data = []
    // GIMME THAT BRIDGE

    for (const b of bridges) {

      if(b.apiMap && b.apiMap.has(m.id)) {
        // console.log(b.apiMap.get(m.id))
        data.push(b.apiMap.get(m.id))
      } else {
        // console.log("No data at " + b.id + " : " + m.id)
        data.push(" ")
      }
    }

    console.log("data:")
    console.log(data)
    const params = m.params !== "" ? `\`${m.params}\`` : " "
    const cells = []
    cells.push(
        [   // First column
          `\`${m.returnType}\` **${m.name} (**` + `${params}` + `**)**`,
          `${m.comment}`
        ]
    )
    for(const cell of data) {
      cells.push(cell)
    }
    const row = new Row(cells)
    membersTable.rows.push(row)
  }

  console.log(membersTable)
  return membersTable
}


export const getBridgesTable = () => {

  // Header
  const bridgesTable = new TableData([
    new Row([ "ID", "Name", "SupportedVersions", "API", "Init_DllVersion", "Curr_DllVersion" ], RowType.HEADER),
  ])

  // Push rows
  for (const b of bridges) {
    if(b.softwareName === "") continue

    const currDll = b.curr_DllVersion !== "" ? `**${b.curr_DllVersion}**` : ""

    const row = new Row([
      `**${b.softwareId}**`,
      `\`${b.softwareName}\``,
      b.softwareVersion, b.apiVersion,
      b.init_DllVersion,
      currDll
    ])
    bridgesTable.rows.push(row)
  }
  return bridgesTable
}