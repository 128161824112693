import {Bridge} from "./Bridge";

// class SoftwareApi {
//
//     constructor(
//         readonly init                 : ClassMember,
//         readonly showConfigForm       : ClassMember,
//         readonly getMetaData          : ClassMember,
//         readonly getCustomers         : ClassMember,
//         readonly getProviders         : ClassMember,
//         readonly getShortNoticeList   : ClassMember,
//         readonly getAppointments      : ClassMember,
//         readonly getProcedures        : ClassMember,
//         readonly getRecares           : ClassMember,
//         readonly setApptsAsConf       : ClassMember,
//         readonly setCommMsgHistory    : ClassMember
//     ) {}
// }

class ApiImplemented {

    constructor(
        readonly init               : string = "",
        readonly showConfigForm     : string = "",
        readonly getMetaData        : string = "",
        readonly getCustomers       : string = "",
        readonly getProviders       : string = "",
        readonly getShortNoticeList : string = "",
        readonly getAppointments    : string = "",
        readonly getProcedures      : string = "",
        readonly getRecares         : string = "",
        readonly setApptsAsConf     : string = "",
        readonly setCommMsgHistory  : string = ""
    ) {}
}

class ClassMember {
    readonly id
    constructor(
       readonly name         : string,
       readonly comment      : string,
       readonly returnType   : string = "void",
       readonly params       : string = ""/*Array<Param> = []*/
    ) {
        this.id = `${this.name} (${this.params})`
    }

    getSignature() {
        return `${this.name} (${this.params})`
    }

}

class Param {

    constructor(
        readonly type   : string,
        readonly name   : string,
    ) {}
}


export { ApiImplemented, ClassMember, Param}