class TableData {

    constructor(
        readonly rows : Array<Row>,
    ) {}
}

class Row {

    constructor(
        readonly cells : /*Array<Cell> | */Array<String> | Array<Array<String>>,
        readonly rowType : RowType = RowType.BODY
    ) {}
}
class Cell {

    constructor(
        readonly data : Array<Data> | String
    ) {}
}

class Data {

    constructor(
        readonly item : string,
        readonly format : Format = Format.TEXT
    ) {}
}

enum RowType {
    HEADER,
    BODY,
    FOOTER
}
enum Format {
    TEXT,
    BOLD,
    ITALIC,
    CODE
}

export { TableData, Row, Cell, Data, RowType, Format }
