import { ApiImplemented} from "./SoftwareAPI";

class BridgeHandler {

    constructor(
        readonly softwareId         :   string,
    ) {}
}

class Bridge {
    readonly id
    constructor(
        readonly softwareId         : string,
        readonly softwareName       : string,
        readonly softwareVersion    : string,
        readonly apiVersion         : string,
        readonly init_DllVersion    : string,
        readonly curr_DllVersion    : string = "",
        readonly apiMap?  : Map<string, string>,
        readonly notes              : string = ""
    ) {
        this.id = this.softwareId
    }
}

class BridgeImplementation {

    constructor(
        readonly bridge             : Bridge,
        readonly api  : ApiImplemented
    ) {}
}

export {Bridge, BridgeHandler, BridgeImplementation}